<template>
    <div class="learn">
        <div class="container">
            <div class="page-hero">
                <h1>{{ $t('page.learn.title') }}</h1>
                <p>{{ $t('page.learn.intro') }}</p>
            </div>
            <div class="four-row">
                <div class="item">
                    <h2><i class="iconfont">&#xe616;</i> {{ $t('page.learn.docs.title') }}</h2>
                    <p>{{ $t('page.learn.docs.description') }}</p>
                    <router-link to="/docs"><span>{{ $t('page.learn.docs.button') }}</span><i class="iconfont">&#xe638;</i></router-link>
                </div>
                <div class="item">
                    <h2><i class="iconfont">&#xe603;</i> {{ $t('page.learn.api.title') }}</h2>
                    <p>{{ $t('page.learn.api.description') }}</p>
                    <a href="https://fairygui.com/api/" target="_blank" rel="noopener noreferrer"><span>{{ $t('page.learn.api.button') }}</span><i class="iconfont">&#xe638;</i></a>
                </div>
                <div class="item">
                    <h2><i class="iconfont">&#xe68a;</i> {{ $t('page.learn.forum.title') }}</h2>
                    <p>{{ $t('page.learn.forum.description') }}</p>
                    <a href="https://ask.fairygui.com/" target="_blank" rel="noopener noreferrer"><span>{{ $t('page.learn.forum.button') }}</span><i class="iconfont">&#xe638;</i></a>
                </div>
                <div class="item">
                    <h2><i class="iconfont">&#xe61d;</i> {{ $t('page.learn.wechat_oa.title') }}</h2>
                    <p>{{ $t('page.learn.wechat_oa.description') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Learn'
}
</script>

<style lang="scss" scoped>
.learn {
    .container:first-child {
        background: url(../assets/decoration/pop-white.svg) no-repeat;
        background-size: 100% auto;
        background-position: center top -50px;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .page-hero {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
            font-size: 48px;
            line-height: 1;
            margin: 0;
            margin-bottom: 40px;
            margin-top: 25px;
        }
        p {
            font-size: 18px;
            text-align: center;
            opacity: .8;
            margin: 0;
        }
    }
    
    .four-row {
        margin-top: 60px;

        .item {
            display: flex;
            flex-direction: column;
        }
        h2 {
            font-size: 24px;
            margin: 0;
            display: flex;
            align-items: center;

            .iconfont {
                font-size: 24px;
                font-weight: normal;
                color: var(--p);
                display: block;
                width: 29px;
                height: 29px;
            }
        }
        p {
            font-size: 14px;
            line-height: 1.75;
            color: #4e4e4e;
            height: 1%;
            flex-grow: 1;
            margin-bottom: 0;
            height: 1%;
            flex-grow: 1;
            margin-bottom: 6px;

            a {
                color: var(--p);
            }
        }
        a {
            display: flex;
            text-decoration: none;
            color: var(--p);
            margin-top: 1em;
            padding: 7px 15px;
            border-radius: 6px;
            background: rgba(#FF971A, .1);
            align-items: center;

            span {
                display: block;
                width: 1%;
                flex-grow: 1;
            }
            &:hover {
                background: rgba(#FF971A, .2);
            }
        }
    }
}
</style>